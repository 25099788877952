<template>
 <div class="flex h-full relative">
  <div @click="showDetail = true" class="absolute top-2 right-0 rounded-xl cursor-pointer">
   <ArrowCircleLeftIcon
    v-if="!showDetail && Object.keys(this.courseDetail).length > 0"
    class="h-8 w-8 text-gray-500 hover:text-gray-600"
    aria-hidden="true"
   />
  </div>
  <!-- Content area -->
  <div class="flex flex-1 flex-col overflow-hidden">
   <!-- Main content -->
   <div class="flex flex-1 items-stretch overflow-hidden">
    <main class="flex-1 overflow-y-auto">
     <div class="mx-auto max-w-7xl px-4 pt-8 sm:px-6 lg:px-8">
      <div class="flex">
       <div class="col-start-2 col-end-3">
        <div class="mt-3 sm:mt-0 sm:ml-0 flex-1">
         <h2 class="capitalize text-xl font-bold leading-7 text-gray-700 sm:text-2xl sm:truncate">
          {{ $t("myCourses") }}
         </h2>
        </div>
       </div>
       <!-- <div
                class="ml-6 flex items-center rounded-lg bg-gray-100 p-0.5 sm:hidden"
              >
                <button
                  type="button"
                  class="rounded-md p-1.5 text-gray-400 hover:bg-white hover:shadow-sm focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                >
                  <Bars4Icon class="h-5 w-5" aria-hidden="true" />
                  <span class="sr-only">Use list view</span>
                </button>
                <button
                  type="button"
                  class="ml-0.5 rounded-md bg-white p-1.5 text-gray-400 shadow-sm focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                >
                  <Squares2X2IconMini class="h-5 w-5" aria-hidden="true" />
                  <span class="sr-only">Use grid view</span>
                </button>
              </div> -->
      </div>
      <section class="mt-8 pb-16" aria-labelledby="gallery-heading">
       <ul
        role="list"
        class="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-1 sm:gap-x-6 md:grid-cols-1 lg:grid-cols-3 xl:grid-cols-5 xl:gap-x-8"
       >
        <li
         v-for="file in courses.slice(0, 10)"
         :key="file.name"
         class="relative"
         @click="setActiveFolder(file.name, file.id)"
        >
         <div
          :class="[
           file.progress < 0.5 && !file.selected
            ? 'bg-red-100'
            : file.progress >= 0.5 && file.progress < 1 && !file.selected
            ? 'bg-blue-100'
            : file.progress == 1 && !file.selected
            ? 'bg-green-100'
            : file.progress < 0.5 && file.selected
            ? 'bg-red-100 border-2 border-red-600 shadow-xl'
            : file.progress >= 0.5 && file.progress < 1 && file.selected
            ? 'bg-blue-100 border-2 border-blue-600 shadow-xl'
            : file.progress == 1 && file.selected
            ? 'bg-green-100 border-2 border-green-600 shadow-xl'
            : '',
           'courses__card--height group block w-full rounded-lg overflow-hidden shadow-lg cursor-pointer px-5 relative',
          ]"
         >
          <div class="py-5">
           <svg
            v-if="file.progress < 0.5"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-10 h-10 rounded-3xl bg-red-200 p-2 text-red-700"
           >
            <path
             stroke-linecap="round"
             stroke-linejoin="round"
             d="M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z"
            />
           </svg>

           <svg
            v-else-if="file.progress >= 0.5 && file.progress < 1"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="2"
            stroke="currentColor"
            class="w-10 h-10 rounded-3xl bg-blue-200 p-2 text-blue-700"
           >
            <path
             stroke-linecap="round"
             stroke-linejoin="round"
             d="M12 6.042A8.967 8.967 0 006 3.75c-1.052 0-2.062.18-3 .512v14.25A8.987 8.987 0 016 18c2.305 0 4.408.867 6 2.292m0-14.25a8.966 8.966 0 016-2.292c1.052 0 2.062.18 3 .512v14.25A8.987 8.987 0 0018 18a8.967 8.967 0 00-6 2.292m0-14.25v14.25"
            />
           </svg>
           <svg
            v-else
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-10 h-10 rounded-3xl bg-green-200 p-2 text-green-700"
           >
            <path
             stroke-linecap="round"
             stroke-linejoin="round"
             d="M4.26 10.147a60.436 60.436 0 00-.491 6.347A48.627 48.627 0 0112 20.904a48.627 48.627 0 018.232-4.41 60.46 60.46 0 00-.491-6.347m-15.482 0a50.57 50.57 0 00-2.658-.813A59.905 59.905 0 0112 3.493a59.902 59.902 0 0110.399 5.84c-.896.248-1.783.52-2.658.814m-15.482 0A50.697 50.697 0 0112 13.489a50.702 50.702 0 017.74-3.342M6.75 15a.75.75 0 100-1.5.75.75 0 000 1.5zm0 0v-3.675A55.378 55.378 0 0112 8.443m-7.007 11.55A5.981 5.981 0 006.75 15.75v-1.5"
            />
           </svg>
          </div>
          <p class="font-medium text-md text-gray-800">
           {{ file.name }}
          </p>
          <div class="absolute inset-x-5 bottom-2">
           <progress-bar :percentage="(file.progress * 100).toFixed(0)" />
          </div>
         </div>
         <!-- <p
                    class="pointer-events-none mt-2 block truncate text-sm font-medium text-gray-900"
                  >
                    {{ file.name }}
                  </p> -->
         <!-- <p
                    class="pointer-events-none block text-sm font-medium text-gray-500"
                  >
                    {{ file.size }}
                  </p> -->
        </li>
       </ul>
       <div v-show="courses && courses.length > 10" class="relative mt-5">
        <div class="absolute inset-0 flex items-center" aria-hidden="true">
         <div class="w-full border-t border-gray-300" />
        </div>
        <div
         @click="showAllFiles = !showAllFiles"
         class="relative flex justify-center cursor-pointer"
        >
         <span class="bg-gray-100 px-2 text-gray-500">
          <!-- <PlusIcon
                      class="h-5 w-5 text-gray-500"
                      aria-hidden="true"
                    /> -->
          {{ showAllFiles ? "Hide" : "View all" }}
         </span>
        </div>
       </div>
      </section>
      <div class="col-start-2 col-end-3">
       <div class="mt-3 sm:mt-0 sm:ml-0 flex-1">
        <h2 class="capitalize text-xl font-bold leading-7 text-gray-700 sm:text-2xl sm:truncate">
         {{ $t("myResults") }}
        </h2>
       </div>
      </div>
      <section class="mt-8 pb-16" aria-labelledby="gallery-heading">
       <div class="grid grid-cols-1 gap-4 sm:grid-cols-2">
        <div
         v-for="evaluation in showAllResults ? quizEvaluations : quizEvaluations.slice(0, 6)"
         :key="evaluation.id"
         :class="[
          evaluation.score < 0.5
           ? 'border-red-200'
           : evaluation.score >= 0.5 && evaluation.score < 1
           ? 'border-blue-200'
           : 'border-green-200',
          'relative flex items-center space-x-3 rounded-lg bg-white px-6 py-5 shadow-lg border-l-8',
         ]"
         @click="$router.push(`/training/quiz-results/${evaluation.uuid}`)"
        >
         <div class="min-w-0 flex-1">
          <a href="#" class="focus:outline-none">
           <span class="absolute inset-0" aria-hidden="true" />
           <p class="text-sm font-medium text-gray-900">
            {{ evaluation.quiz }}
           </p>
           <p
            :class="[
             evaluation.passed ? 'text-green-500' : 'text-red-500',
             'truncate text-sm flex gap-1 items-center',
            ]"
           >
            <svg
             v-if="evaluation.score < 0.5"
             xmlns="http://www.w3.org/2000/svg"
             fill="none"
             viewBox="0 0 24 24"
             stroke-width="1.5"
             stroke="currentColor"
             class="w-10 h-10 rounded-3xl bg-red-200 p-2 text-red-700"
            >
             <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z"
             />
            </svg>

            <svg
             v-else-if="evaluation.score >= 0.5 && evaluation.score < 1"
             xmlns="http://www.w3.org/2000/svg"
             fill="none"
             viewBox="0 0 24 24"
             stroke-width="2"
             stroke="currentColor"
             class="w-10 h-10 rounded-3xl bg-blue-200 p-2 text-blue-700"
            >
             <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M12 6.042A8.967 8.967 0 006 3.75c-1.052 0-2.062.18-3 .512v14.25A8.987 8.987 0 016 18c2.305 0 4.408.867 6 2.292m0-14.25a8.966 8.966 0 016-2.292c1.052 0 2.062.18 3 .512v14.25A8.987 8.987 0 0018 18a8.967 8.967 0 00-6 2.292m0-14.25v14.25"
             />
            </svg>
            <svg
             v-else
             xmlns="http://www.w3.org/2000/svg"
             fill="none"
             viewBox="0 0 24 24"
             stroke-width="1.5"
             stroke="currentColor"
             class="w-10 h-10 rounded-3xl bg-green-200 p-2 text-green-700"
            >
             <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M4.26 10.147a60.436 60.436 0 00-.491 6.347A48.627 48.627 0 0112 20.904a48.627 48.627 0 018.232-4.41 60.46 60.46 0 00-.491-6.347m-15.482 0a50.57 50.57 0 00-2.658-.813A59.905 59.905 0 0112 3.493a59.902 59.902 0 0110.399 5.84c-.896.248-1.783.52-2.658.814m-15.482 0A50.697 50.697 0 0112 13.489a50.702 50.702 0 017.74-3.342M6.75 15a.75.75 0 100-1.5.75.75 0 000 1.5zm0 0v-3.675A55.378 55.378 0 0112 8.443m-7.007 11.55A5.981 5.981 0 006.75 15.75v-1.5"
             />
            </svg>
            <!-- {{ evaluation.passed ? "Aproved" : "Reproved" }} -->
           </p>
          </a>
         </div>
         <div class="flex-1">
          <progress-bar :percentage="evaluation.score * 100" />
         </div>
        </div>
       </div>
       <div v-show="quizEvaluations && quizEvaluations.length > 6" class="relative mt-5">
        <div class="absolute inset-0 flex items-center" aria-hidden="true">
         <div class="w-full border-t border-gray-300" />
        </div>
        <div
         @click="showAllResults = !showAllResults"
         class="relative flex justify-center cursor-pointer"
        >
         <span class="bg-gray-100 px-2 text-gray-500">
          <!-- <PlusIcon
                      class="h-5 w-5 text-gray-500"
                      aria-hidden="true"
                    /> -->
          {{ showAllResults ? "Hide" : "View all" }}
         </span>
        </div>
       </div>
      </section>
     </div>
    </main>

    <!-- Details sidebar -->
    <aside
     v-if="showDetail"
     class="relative w-96 overflow-y-auto border-l border-gray-200 bg-white p-8 lg:block"
    >
     <div @click="showDetail = false" class="fixed rounded-xl cursor-pointer">
      <ArrowCircleRightIcon
       v-if="showDetail"
       class="h-8 w-8 text-gray-500 hover:text-gray-600"
       aria-hidden="true"
      />
     </div>
     <div class="fixed aside__max-height top-36 w-96 pr-14 pb-2">
      <div class="space-y-6 pb-16">
       <div>
        <div class="aspect-w-10 aspect-h-7 block w-full overflow-hidden rounded-lg">
         <img :src="currentFile.source" alt="" class="h-full" />
        </div>
        <div class="mt-4 flex items-start justify-between">
         <div>
          <h2 class="text-lg font-medium text-gray-900">
           <span class="sr-only">Details for </span>{{ courseDetail.name }}
          </h2>
          <!-- <p class="text-sm font-medium text-gray-500">
                    {{ currentFile.size }}
                  </p> -->
         </div>
         <!-- <button
                  type="button"
                  class="ml-4 flex h-8 w-8 items-center justify-center rounded-full bg-white text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                >
                  <HeartIcon class="h-6 w-6" aria-hidden="true" />
                  <span class="sr-only">Favorite</span>
                </button> -->
        </div>
       </div>
       <div>
        <h3 class="font-medium text-gray-900 capitalize">{{ $t("information") }}</h3>
        <dl class="mt-2 divide-y divide-gray-200 border-t border-b border-gray-200 capitalize">
         <!-- <div class="flex justify-between py-3 text-sm font-medium">
          <dt class="text-gray-500">{{ $t("createdAt") }}</dt>
          <dd class="whitespace-nowrap text-gray-900">
           {{ formatDate(courseDetail.created_at) }}
          </dd>
         </div>
         <div class="flex justify-between py-3 text-sm font-medium">
          <dt class="text-gray-500">{{ $t("audience") }}</dt>
          <dd class="whitespace-nowrap text-gray-900">
           {{ courseDetail.audience }}
          </dd>
         </div>
         <div class="flex justify-between py-3 text-sm font-medium">
          <dt class="text-gray-500">{{ $t("published") }}</dt>
          <dd class="whitespace-nowrap text-gray-900">
           {{ courseDetail.published ? $t("yes") : $t("no") }}
          </dd>
         </div>
         <div class="flex justify-between py-3 text-sm font-medium">
          <dt class="text-gray-500">{{ $t("buyable") }}</dt>
          <dd class="whitespace-nowrap text-gray-900">
           {{ courseDetail.buyable ? $t("yes") : $t("no") }}
          </dd>
         </div> -->
         <div v-if="currentFile.price" class="flex justify-between py-3 text-sm font-medium">
          <dt class="text-gray-500">{{ $t("price") }}</dt>
          <dd class="whitespace-nowrap text-gray-900">
           {{ currentFile.price }}
          </dd>
         </div>
         <div class="flex justify-between py-3 text-sm font-medium">
          <dt class="text-gray-500">{{ $t("free") }}</dt>
          <dd class="whitespace-nowrap text-gray-900">
           {{ courseDetail.free ? $t("yes") : $t("no") }}
          </dd>
         </div>
        </dl>
       </div>
       <div>
        <h3 class="font-medium text-gray-900 capitalize">{{ $t("excerpt") }}</h3>
        <div class="mt-2 flex items-center">
         <span v-html="courseDetail.excerpt"></span>
        </div>
       </div>
       <div v-if="courseDetail.lessons && courseDetail.lessons.length > 0">
        <h3 class="font-medium text-gray-900 capitalize">{{ $t("lessons") }}</h3>
        <ul role="list" class="mt-2 divide-y divide-gray-200 border-t border-b border-gray-200">
         <li
          v-for="lesson in courseDetail.lessons"
          :key="lesson.id"
          class="flex items-center justify-between py-3"
          @click="$router.push(`/training/lesson/${lesson.id}`)"
         >
          <div class="flex items-center">
           <!-- <img
                      :src="lesson.imageUrl"
                      alt=""
                      class="h-8 w-8 rounded-full"
                    /> -->
           <p class="ml-4 text-sm font-medium text-gray-900">
            {{ lesson.title }}
           </p>
          </div>
          <button
           type="button"
           class="ml-6 rounded-md bg-white text-sm font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
          >
           {{ $t("open") }}
          </button>
         </li>
         <!-- <li class="flex items-center justify-between py-2">
                  <button
                    type="button"
                    class="group -ml-1 flex items-center rounded-md bg-white p-1 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                  >
                    <span
                      class="flex h-8 w-8 items-center justify-center rounded-full border-2 border-dashed border-gray-300 text-gray-400"
                    >
                      <PlusIconMini class="h-5 w-5" aria-hidden="true" />
                    </span>
                    <span
                      class="ml-4 text-sm font-medium text-indigo-600 group-hover:text-indigo-500"
                      >Share</span
                    >
                  </button>
                </li> -->
        </ul>
       </div>
       <div class="flex">
        <button
         @click="this.$router.push(`/training/course/${courseDetail.id}`)"
         type="button"
         class="flex-1 rounded-md border border-transparent py-2 px-4 text-sm font-medium text-white shadow-sm focus:outline-none body__button"
        >
         {{ $t("goTo") }}
        </button>
        <!-- <button
                type="button"
                class="ml-3 flex-1 rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              >
                Delete
              </button> -->
       </div>
      </div>
     </div>
    </aside>
   </div>
  </div>
 </div>
</template>

<script>
import axios from "axios";
import ProgressBar from "../../components/ProgressBar.vue";
import {
 Dialog,
 DialogPanel,
 Menu,
 MenuButton,
 MenuItem,
 MenuItems,
 TransitionChild,
 TransitionRoot,
} from "@headlessui/vue";
import {
 Bars3BottomLeftIcon,
 CogIcon,
 HeartIcon,
 HomeIcon,
 PhotoIcon,
 PlusIcon as PlusIconOutline,
 RectangleStackIcon,
 UserGroupIcon,
 XMarkIcon,
 FolderOpenIcon,
} from "@heroicons/vue/outline";
import {
 Bars4Icon,
 MagnifyingGlassIcon,
 PencilIcon,
 PlusIcon,
 ArrowCircleRightIcon,
 ArrowCircleLeftIcon,
} from "@heroicons/vue/solid";

const tabs = [
 { name: "Available", href: "#", current: true },
 { name: "All courses", href: "#", current: false },
];
const currentFile = {
 name: "IMG_4985.HEIC",
 size: "3.9 MB",
 source:
  "https://images.unsplash.com/photo-1582053433976-25c00369fc93?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=512&q=80",
 information: {
  "Uploaded by": "Marie Culver",
  Created: "June 8, 2020",
  "Last modified": "June 8, 2020",
  Dimensions: "4032 x 3024",
  Resolution: "72 x 72",
 },
 sharedWith: [
  {
   id: 1,
   name: "Aimee Douglas",
   imageUrl:
    "https://images.unsplash.com/photo-1502685104226-ee32379fefbe?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=3&w=1024&h=1024&q=80",
  },
  {
   id: 2,
   name: "Andrea McMillan",
   imageUrl:
    "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixqx=oilqXxSqey&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
  },
 ],
};

export default {
 components: {
  Bars4Icon,
  MagnifyingGlassIcon,
  PencilIcon,
  Bars3BottomLeftIcon,
  CogIcon,
  HeartIcon,
  HomeIcon,
  PhotoIcon,
  RectangleStackIcon,
  UserGroupIcon,
  XMarkIcon,
  PlusIconOutline,
  FolderOpenIcon,
  PlusIcon,
  Dialog,
  DialogPanel,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  TransitionChild,
  TransitionRoot,
  ProgressBar,
  ArrowCircleRightIcon,
  ArrowCircleLeftIcon,
 },
 data() {
  return {
   currentFile,
   courses: [],
   tabs,
   quizEvaluations: [],
   showAllResults: true,
   showDetail: false,
   courseDetail: {},
  };
 },
 methods: {
  setActiveFolder(name, id) {
   this.courses.map((res) => {
    res.name === name ? (res.selected = true) : (res.selected = false);
   });
   this.getCourse(id);
   this.showDetail = true;
  },
  async setEvaluationList(data) {
   let obj = {};
   this.quizEvaluations = [];
   for (let index = 0; index < data.length; index++) {
    const element = data[index];
    let evaluationData = await this.getQuizEvaluationData(element.uuid);
    obj = {};
    obj["id"] = index;
    obj["uuid"] = element.uuid;
    obj["passed"] = element.passed;
    obj["time"] = element.time_seconds;
    obj["score"] = element.score_percent;
    obj["date"] = element.completed_at;
    obj["quiz"] =
     evaluationData && evaluationData.quiz && evaluationData.quiz.subject
      ? evaluationData.quiz.subject
      : "";
    this.quizEvaluations.push(obj);
   }
   this.$emit("setLoading", false);
  },
  async getEvaluations() {
   this.$emit("setLoading", true);
   const url = "api/v1/training/quiz-evaluations";
   try {
    const res = await axios.get(`${this.$cookie.getCookie("API")}/${url}`);
    console.log("file: TrainingTools.vue ~ line 484 ~ getEvaluations ~ res.data", res.data);
    this.setEvaluationList(res.data);
   } catch (error) {
    this.errorHandling(error);
   }
  },
  async getUserCourses() {
   this.$emit("setLoading", true);
   const url = "api/v1/training/courses";
   try {
    const res = await axios.get(`${this.$cookie.getCookie("API")}/${url}`);
    console.log("file: TrainingTools.vue ~ line 583 ~ getUserCourses ~ res", res.data);
    this.setCourses(res.data);
   } catch (error) {
    this.errorHandling(error);
   }
  },
  async getCourse(id) {
   this.$emit("setLoading", true);
   const url = `api/v1/training/courses/${id}`;
   try {
    const res = await axios.get(`${this.$cookie.getCookie("API")}/${url}`);
    console.log("file: TrainingTools.vue ~ line 583 ~ getUserCourses ~ res", res.data);
    this.courseDetail = res.data;
    this.$emit("setLoading", false);
   } catch (error) {
    this.errorHandling(error);
    this.$emit("setLoading", false);
   }
  },
  async setCourses(data) {
   this.courses = [];
   data.forEach(async (element) => {
    let obj = {};
    obj["id"] = element.id;
    obj["name"] = element.name;
    obj["current"] = false;
    obj["progress"] = await this.getCourseProgress(element.id);
    obj["selected"] = false;
    this.courses.push(obj);
   });
  },
  async getCourseProgress(id) {
   try {
    const url = `api/v1/training/lessons?course_id=${id}`;
    const res = await axios.get(`${this.$cookie.getCookie("API")}/${url}`);
    let completedLessons = res.data.filter((lesson) => {
     return lesson.completed_at;
    });
    let progress = completedLessons.length / res.data.length;
    return progress;
   } catch (error) {
    this.errorHandling(error);
   }
  },
  async getQuizEvaluationData(uuid) {
   this.$emit("setLoading", true);
   let url = `${this.$cookie.getCookie("API")}/api/v1/training/quiz-evaluations/${uuid}`;
   try {
    const res = await axios.get(url);
    console.log("file: TrainingTools.vue ~ line 504 ~ getQuizEvaluationData ~ res.data", res.data);
    return res.data;
   } catch (error) {
    this.errorHandling(error);
   }
  },
 },

 mounted() {
  this.getEvaluations();
  this.getUserCourses();
 },
};
</script>

<style scoped>
.courses__card--height {
 height: 200px;
}
</style>
